import Auth from '@aws-amplify/auth';

export async function Init(): Promise<void> {
  const config = {
    stage: "beta",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:09e02ea5-2635-4ca7-a082-a1846c836197",
    aws_user_pools_id: "us-east-1_6RHm0OOV5",
    aws_user_pools_web_client_id: "41nbaeeegfmor0r891mkus6p4t",
    Auth: {
      identityPoolId: "us-east-1:09e02ea5-2635-4ca7-a082-a1846c836197",
      region: "us-east-1",
      userPoolId: "us-east-1_6RHm0OOV5",
      userPoolWebClientId: "41nbaeeegfmor0r891mkus6p4t",
    },
    oauth: {
      domain: `rtw-tool-beta-na.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: "https://beta.na.rtw.whs.amazon.dev",
      redirectSignOut: "https://beta.na.rtw.whs.amazon.dev",
      scope: ['openid', 'profile'],
      responseType: 'code',
    },
  };
  Auth.configure(config);
}